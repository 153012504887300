<template>
  <q-form ref="editForm">
    <c-card class="cardClassDetailForm" title="LBLDETAIL">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable&&!disabled&&isOld" 
            label="LBLREMOVE" 
            icon="delete" 
            @btnClicked="deleteInvestigation" />
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="investigation"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveInvestigation"
            @btnCallback="saveInvestigationCallback" />
          <c-btn 
            v-show="completeBtnEditable" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="investigation"
            mappingType="PUT"
            label="설문 요청" 
            icon="check"
            @beforeAction="completeInvestigation"
            @btnCallback="completeInvestigationCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-text
            :required="true"
            :disabled="disabled"
            :editable="editable"
            label="조사계획명"
            name="investigationPlanName"
            v-model="investigation.investigationPlanName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-datepicker
            :required="true"
            :disabled="disabled"
            :editable="editable"
            type="year"
            default="today"
            label="LBLYEAR"
            name="year"
            v-model="investigation.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-datepicker
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :range="true"
            label="조사기간"
            name="period"
            v-model="investigation.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-field
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :data="investigation"
            deptValue="deptCd"
            type="dept_user"
            label="LBLMANAGER"
            name="userId"
            v-model="investigation.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-plant 
            :required="true"
            :disabled="isOld"
            :editable="editable"
            type="edit" 
            name="plantCd" 
            v-model="investigation.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-select
            disabled
            :editable="editable"
            codeGroupCd="HEA_SURVEY_CATEGORY_CD"
            itemText="codeName"
            itemValue="code"
            type="edit" 
            label="조사구분"
            name="heaSurveyCategoryCd" 
            v-model="investigation.heaSurveyCategoryCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="5"
            label="비고"
            name="remark"
            v-model="investigation.remark">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled"
            label="LBLATTACHMENT">
          </c-upload>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'investigationPlanEveryYearDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',
        heaSurveyCategoryCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      investigation: {
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        plantCd: '',  // 사업장코드
        investigationPlanName: '',  // 조사계획명
        year: '',  // 심사년도
        heaInvestigationStepCd: '',  // 진행상태
        heaSurveyCategoryCd: '',  // 조사구분
        investigationStartDate: '',  // 조사시작일
        investigationEndDate: '',  // 조사종료일
        period: [],
        deptCd: '',  // 담당자 부서
        userId: '',  // 담당자 id
        investigationDeptCd: '',  // 조사부서
        agency: '',  // 조사기관
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        sops: [], // 단위작업
        sopTable: [], // 작업분류표
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'HEA_INVESTIGATION_PLAN',
        taskKey: '',
      },
      editable: true,
      isSave: true,
      isComplete: true,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      mappingType: 'PUT',
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.heaInvestigationPlanId)
    },
    disabled() {
      return this.investigation.heaInvestigationStepCd !== 'HI00000001';
    },
    completeBtnEditable() {
      return this.editable 
        && !this.disabled 
        && this.isOld
    },
  },
  watch: {
  },  
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.muscleSystem.investigationPlan.get.url
      this.saveUrl = transactionConfig.hea.muscleSystem.investigationPlan.insert.url
      this.deleteUrl = transactionConfig.hea.muscleSystem.investigationPlan.delete.url
      this.completeUrl = transactionConfig.hea.muscleSystem.investigationPlan.complete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {    
        this.$http.url = this.$format(this.detailUrl, this.popupParam.heaInvestigationPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.investigation, _result.data)
          this.$set(this.investigation, 'period', [this.investigation.investigationStartDate, this.investigation.investigationEndDate])
          this.$set(this.attachInfo, 'taskKey', this.popupParam.heaInvestigationPlanId)
        },);
      } else {
        this.$set(this.investigation, 'heaSurveyCategoryCd', this.popupParam.heaSurveyCategoryCd)
        this.$set(this.investigation, 'heaInvestigationStepCd', 'HI00000001')
        this.$set(this.investigation, 'userId', this.$store.getters.user.userId)
        this.$set(this.investigation, 'deptCd', this.$store.getters.user.deptCd)
        this.$set(this.investigation, 'year', this.$comm.getThisYear())
      }
    },
    saveInvestigation() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.hea.muscleSystem.investigationPlan.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.hea.muscleSystem.investigationPlan.insert.url
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.investigation.regUserId = this.$store.getters.user.userId
              this.investigation.chgUserId = this.$store.getters.user.userId
              this.$set(this.investigation, 'investigationStartDate', this.investigation.period[0])
              this.$set(this.investigation, 'investigationEndDate', this.investigation.period[1])

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveInvestigationCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'heaInvestigationPlanId', _result.data)
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail()
    },
    completeInvestigation() {      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '해당 사업장의 전직원에게 설문 요청하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.investigation.chgUserId = this.$store.getters.user.userId
              this.$set(this.investigation, 'investigationStartDate', this.investigation.period[0])
              this.$set(this.investigation, 'investigationEndDate', this.investigation.period[1])

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeInvestigationCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail()
    },
    deleteInvestigation() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.heaInvestigationPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
